.App {
  text-align: center;
}

body {
  background-color: rgb(43, 42, 42); /* Change the background color to grey */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* This ensures that the container takes at least the full height of the viewport */
}

#root {
  flex: 1; /* This allows the root container to grow and take up the remaining space */
}


.Details {
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Optionally, to center vertically within the viewport */

}

