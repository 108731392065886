/* Footer.css */

.footer {
    background-color: #00274c;
    color: #808080;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo img {
  width: 50px; /* Adjust the size as needed */
  margin-right: 10px;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  display: inline-block;
  margin-right: 20px;

}

.footer-links a {
  text-decoration: none;
   color: #808080; /* University of Nevada silver color for text */
  ;
}

.footer-social a {
  color:  #808080;
  margin-right: 10px;
  text-decoration: none;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
}